export default {
    name: 'Utility',
    data: () => ({
        pricetable: {
            sortBy: ['name'],
            itemsPerPage: -1
        },
        priceheader: [
            { text: 'Name', value: 'itemNameImported' },
            { text: 'Size', value: 'size' },
            { text: '', value: 'edit' },
            { text: 'Active', value: 'active' }
        ],
        search: '',
        showprice: false,
        tiermod: [],
        showinactiveparts: false,
        currentgroup: [],
        recsdisplayed: 0,
        effectivedate: new Date().toLocaleDateString(),
        pickermenu: '',
        pickerefdate: '',
        efdatestart: (new Date()).toISOString().slice(0, 10)
    }),
    mounted() {
        this.refresh()

        let worktablestate = localStorage.getItem('pricetable')
        let worksearch = localStorage.getItem('pricesearch')

        if (worktablestate) {
            this.jsftable = JSON.parse(worktablestate)
        }

        if (worksearch) {
            this.search = worksearch
        }

        if (this.search === 'null') {
            this.search = ''
        }
    },
    computed: {
        partsprice: { get() { return this.$store.getters['Utility/getPartsPrice'] }, set(val) { this.$store.commit('Utility/SET_PARTSPRICE', val) } },
        ppactive: { get() { return this.$store.getters['Utility/getPpActive'] }, set(val) { this.$store.commit('Utility/SET_PARTSPRICEINACTIVE', val) } },
        ppinactive: { get() { return this.$store.getters['Utility/getPpInactive'] }, set(val) { this.$store.commit('Utility/SET_PARTSPRICEINACTIVE', val) } },
        currenttiers: { get() { return this.$store.getters['Utility/getCurrentTiers'] }, set(val) { this.$store.commit('Utility/SET_TIERS', val) } },
    },
    methods: {
        refresh() {
            this.$store.dispatch('Utility/fetchPartsPrice', null, { root: true })
                .then(() => {
                    let workshowinactive = localStorage.getItem('showinactive')

                    if (workshowinactive === 'true') {
                        this.showinactiveparts = true
                        this.currentgroup = this.partsprice
                    } else {
                        this.currentgroup = this.ppactive
                    }
                })

        },
        gettotalpriceitems(e) {
            this.recsdisplayed = e.itemsLength
        },
        handleActive(item) {
            /* --- fuzzy here, sometimes we get a string, other times we get a number --- */
            if (item.active == 0) {
                item.active = true
            } else {
                item.active = false
            }

            this.$store.dispatch('Utility/modActive', { tierid: item.pricingMatrixID, partid: item.specialtyItemID, active: item.active }, { root: true })
                .then(() => {
                    this.refresh()
                })
        },
        handleInactiveDisplay(e) {
            if (e) {
                this.currentgroup = this.partsprice
            } else {
                this.currentgroup = this.ppactive
            }

            localStorage.setItem('showinactive', e)
        },
        populatePriceTiers(matrixid) {
            this.showprice = true

            this.$store.dispatch('Utility/fetchTiers', { matrixid: matrixid }, { root: true })
        },
        cancelPriceTiers() {
            this.showprice = false
            this.tiermod = []
        },
        savePriceTiers() {
            //console.log('WILL SAVE TIERS: ' + this.tiermod)
            //console.log(this.currenttiers.ranges)
            let worker = {}

            for (let idx = 0; idx < this.tiermod.length; idx++) {
                if (this.currenttiers.ranges.find(o => o.id === this.tiermod[idx])) {
                    worker = this.currenttiers.ranges.find(o => o.id === this.tiermod[idx])

                    this.$store.dispatch('Utility/savePrice', { tierid: worker.id, price: worker.price, effectivedate: this.effectivedate }, { root: true })
                        .then(() => {
                            this.$store.dispatch('Utility/fetchTiers', { matrixid: this.currenttiers.matrixid }, { root: true })
                            //this.showprice = false
                        })
                }
            }


            this.tiermod = []
        },
        removeTier(tier, matrixid) {
            let resp = confirm('Are you sure you want to remove the ' + tier.upper + ' count price ' + tier.price + ' effective on ' + tier.effective_date + '?')

            if (resp) {
                this.$store.dispatch('Utility/nixTier', { tierid: tier.id }, { root: true })
                    .then(() => {
                        this.$store.dispatch('Utility/fetchTiers', { matrixid: matrixid }, { root: true })
                    })
            }
        },
        modTierPrice(id) {
            this.tiermod.push(id)
        },
        filterit(what) {
            this.search = what;
            localStorage.setItem('pricesearch', this.search)
        },
        clearstext() {
            localStorage.removeItem('pricesearch');
        },
        setEfDate() {
            let setdate = new Date(this.pickerefdate + ' 00:00:00').toLocaleDateString()

            this.effectivedate = setdate
        }
    }

}